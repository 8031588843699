.mapBoxWrapper {
  position: relative;
  height: 100%;

  .pointPropsPopup {
    .mapboxgl-popup-content {
      padding: 0;

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .popupClose {
    div {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;
      margin: 0px;
      margin-bottom: -10px;
    }
  }

  .popupOpen {
    opacity: 0.85;

    &.notip {
      .mapboxgl-popup-tip {
        display: none;
      }  
    }

    .mapboxgl-popup-tip {
      border: 5px solid transparent;
      border-right-color: #fff;
      margin-bottom: 6px;
    }

    .mapboxgl-popup-content {
      border: 1px solid #c6c6c6;
      border-radius: 4px;
      border-bottom: none;
      padding: 2px 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mapBoxPopupContent {
    display: flex;
    align-items: center;
    padding: 0px;

    .name {
      user-select: none;
      color: black;
      font-weight: 400;
      font-size: 16px;
      margin: 0px 5px;
    }

    .closeIcon {
      cursor: pointer;
      fill: #bbbbbb;

      &:hover {
        fill: @green_01;
      }
    }
  }

  .mapBoxPopupIcon {
    background-image: url('../../icons/ic_geofence_popup.png');
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
  }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapHint {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: @green_01;
  padding: 0 5px;
  border-radius: 5px;
  left: 30%;
  transform: translate(-30%, 10px);
  z-index: 999;
  max-width: 650px;
  padding: 4px;

  &.drawerShown {
    left: 13%;
    max-width: 330px;
    text-align: center;
  }
}

.mapSidebarWrapper {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 5px;

  .collapseSideBarBtnMap {
    display: flex;
    align-items: center;
  }

  .mapToolbarWrapper {
    position: relative;
    height: 100%;

    .openToolbarBtn {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .mapToolActions {
      position: absolute;
      bottom: 15px;
      min-width: 280px;
      right: 10px;

      button + button {
        margin-left: 6px;
      }
    }

    .toolbarContentDefaultState {
      width: 325px;
      height: 100%;
      margin-right: -350px;
      background-color: @white;
    }

    .toolbarContentAnimationOpen {
      width: 325px;
      height: 100%;
      margin-right: -325px;
      background-color: @white;

      .keyframes(fade-open;{
          from {  margin-right: -325px; }
          to {  margin-right: 0 }
          });

      .animation(fade-open 0.3s 1 forwards);
    }

    .toolbarContentAnimationClose {
      // TODO &:extend(.toolbarContentOpen); -> not working ? TODO
      width: 325px;
      height: 100%;
      background-color: @white;

      .keyframes(fade-close;{
          from {  margin-right: 0; }
          to {  margin-right: -325px }
          });

      .animation(fade-close 0.3s 1 forwards);
    }

    .toolbarContent {
      padding: 40px 10px 10px 20px;
    }
  }

  .colorPickerWrapper + .colorPickerWrapper {
    margin-top: 5px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: @white;
  }
}

.drawStylesContent {
  display: grid;
  gap: 20px;
  width: 100%;

  .title {
    margin-bottom: -15px;
    font-weight: 600;
  }

  &._10 {
    gap: 10px;

    .title {
      margin-bottom: -5px;
    }
  }

  .validateField {
    display: grid;
    gap: 0;

    .inValidInput {
      border-color: red;
    }
    .inValidText {
      color: red;
    }
  }

  .strokeWidth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider {
      width: 150px;
    }
  }
}

.drawStylesContent + .drawStylesContent {
  margin-top: 10px;
}

.drawModeContent {
  .title {
    font-weight: 600;
  }

  .drawTools {
    display: flex;
    margin-top: 12px;

    button {
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 10px;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .drawTools + .drawTools {
    margin-top: 5px;
  }
}

.keyframes(@name; @arguments) {
  @-moz-keyframes @name {
    @arguments();
  }
  @-webkit-keyframes @name {
    @arguments();
  }
  @keyframes @name {
    @arguments();
  }
}

.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

.notificationNoTokenWrapper {
  padding: 20px;
}

.colorPickerWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    padding: 0;

    &:after {
      animation: none !important;
    }

    &:active,
    &:hover {
      background-color: @white;
      color: @grey_03 !important;
      cursor: pointer;
    }

    .circleColorValue {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .colorPickerWidget {
    position: absolute;

    top: -50px;
    left: -240px;

    height: 300px;
    width: 220px;
    background-color: @white;

    .header {
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: space-between;

      span {
        font-weight: 600;
      }
    }

    .colorPicker {
      margin-top: 5px;
    }
  }

  .title {
    padding-left: 5px;
  }
}

.modalPointProperties {
  padding: 8px 16px;

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: @white;
    padding: 4px 16px;
  }

  fieldset {
    margin-top: 16px;
    border: 1px #d9d9d9 solid;
    border-radius: 4px;
    padding: 12px;

    legend {
      width: auto;
      font-size: 14px;
      margin: 0 auto;
      padding-left: 8px;
      padding-right: 8px;
    }

    &.noPadd {
      padding: 0;
    }
  }

  .button {
    .circleColorValue {
      border: 2px solid @grey_04;
    }
  }

  .ant-btn.button {
    display: flex;
    align-items: center;
  }

  .icon-container {
    border: 1px solid @grey_04;
    border-radius: 4px;
    display: grid;
    gap: 8px;
    grid-template-rows: 28px 28px;
    grid-template-columns: repeat(auto-fill, 28px);
    padding: 8px;

    &__item {
      width: 28px;
      height: 28px;
      border: @grey_04 solid 2px;
      border-radius: 4px;
      transition: all 0.3s;

      path {
        fill: black;
      }

      &:hover {
        border-color: @green_01;
        cursor: pointer;
      }

      &--active {
        border-color: @green_01;
      }
    }
  }

  .form_content {
    height: 400px;

    &.scroll-y {
      overflow-y: scroll;
    }

    .editable-cell {
      position: relative;
    }

    .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
    }

    .ant-table-thead .ant-table-cell {
      padding-left: 24px;
    }

    .ant-table-cell {
      &:last-child {
        path {
          fill: @red;
        }
      }

      &:hover {
        a {
          cursor: pointer;
        }
      }
    }
  }
}
