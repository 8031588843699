// ATOMIC MARGIN
// ===============================================
.mt8 {
  margin-top: 8px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr24 {
  margin-right: 24px !important;
}

// ATOMIC PADDING
// ===============================================
.pd12 {
  padding: 12px !important;
}

.pd24 {
  padding: 24px !important;
}

// Z-INDEX
// ===============================================
.zi99 {
  z-index: 99 !important;
}

// BORDER
// ===============================================
.noneBorder {
  border: none !important;
  box-shadow: none;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.fb75 {
  flex-basis: 75% !important;
}

.fb80 {
  flex-basis: 80% !important;
}

.fb100 {
  flex-basis: 100% !important;
}

// FONT SIZE
// ===============================================
.fz12 {
  font-size: 12px !important;
}

.text-bold {
  font-weight: bold;
}

// ATOMIC FLEX
// ===============================================
.flex {
	display: flex !important;

  &._center {
    align-items: center;
    justify-content: center;
  }

  &._align_center {
    align-items: center;
  }

  &._row {
    flex-direction: row !important;
  }

  &._column {
    flex-direction: column !important;
  }

  &._jc-space-between {
		justify-content: space-between;
  }

  &._fg1 {
		flex-grow: 1;
  }

  &._gap6 {
    > *:not(:last-child) {
      margin-right: 6px;
    }
  }

  &._gap_top16 {
    > *:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.line {
  border-top: 1px solid @border-color;

  &._12 {
    margin: 12px 0;
  }
}

.txt-left {
  text-align: left;
}

// DISPLAY
// ===============================================

.d_none {
  display: none !important;
}