:root {
  --green_01: #afcb43;
  --green_02: #f2f5d8;
  --green_03: #92a93a;
  --green_04: #afcc46;
  --green_05: #d9e5a6;

  --grey_01: #2a2728;
  --grey_02: #363334;
  --grey_03: #626061;
  --grey_04: #e8e8e8;
  --grey_05: #4b4949;
  --grey_06: #3e3c3d;
  --grey_07: #f5f5f5;
  --grey_08: #cfcdcd;

  --blue_01: #1f7ae6;

  --white: #ffffff;

  --red: #e41d1a;
  --red_01: #c6000c;

  --black: #2a2a29;

  /** new tassta design system */
  --green2: #91a93a;
  --gray2: #3e3c3d;
  --gray3: #4b4949;
  --gray4: #706d6d;
  --gray5: #9c9a9a;
  --red3: #ffc1a0;
  /** the end of design system */

  --primary-color: var(--green_01);
  --main-background: var(--white);
  --sidebar-background: var(--grey_02);
  --input-placeholder-color: var(--grey_04);
  --notification-color: var(--grey_02);
  --dark-text-color: var(--grey_02);
  --light-text-color: var(--grey_03);
  --error-color: var(--red);
  --border-color: var(--grey_04);
  --divider-color: var(--grey_04);
}
