.page404 {
  @font-face {
    font-family: Roboto-Light;
    src: url(../fonts/Roboto-Light.ttf);
  }

  font-family: 'Roboto-Light';
  background-color: @white;
  line-height: normal;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    text-align: center;
    min-height: 100vh;
    color: @grey_01;
    font-weight: 300;
  }

  &__title {
    font-size: 87px;
  }

  &__desc {
    font-size: 30px;
    color: @grey_03;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    width: 197px;
    height: 48px;
    background-color: white;
    color: @grey_03;
    border: 1px solid @grey_04;
    font-size: 18px;
    border-radius: 10px;
  }
}
