.callDetails {
  display: flex;
  width: auto !important;
  justify-content: center;
}

.callDetailsPage {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.hidden {
    display: none;
  }

  .navPanel {
    display: flex;
    border-bottom: 1px solid @grey_07;

    .navPanel__content {
      margin: 16px 0;
      margin-left: 20px;

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .arrowBackIcon {
        margin-right: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      div {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: @grey_05;
        cursor: default;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding: 27px;
    padding-bottom: 77px;
    overflow: hidden;
    flex-grow: 1;

    .contentWrapper__info {
      display: grid;
      grid-template-columns: repeat(3, minmax(100px, 250px));
      row-gap: 16px;
      column-gap: 27px;
      align-content: start;
      margin-bottom: 27px;
      font-size: 14px;
      line-height: 16px;
      color: @grey_05;

      span {
        margin-right: 10px;
      }
    }

    .contentWrapper__table {
      max-width: 1400px;
      flex-grow: 1;

      .ant-table-header {
        table {
          background-color: @grey_07;
        }
        thead {
          .ant-table-cell {
            &::before {
              display: none;
            }
          }
          th {
            padding: 16px;
            font-weight: 700;
            background-color: @grey_07;
          }
        }
      }

      .ant-table-body {
        td {
          cursor: default;
        }

        .ant-table-cell {
          &:last-child {
            padding: 0;
          }
        }
      }

      .ant-table-container {
        border: none;
      }
    }
  }
}

.videoPlayerTitle {
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: @gray3;

  .ptt_icon {
    width: 20px;
    height: 20px;
    background-color: @green2;
    mask: url('../../icons/ic_ptt_call.svg') no-repeat center;
    mask-size: 20px 20px;

    margin-right: 8px;
  }
}

.marker-colors {
  &.Request {
    background-color: @red;
  }

  &.Granted {
    background-color: @green_05;
    color: @grey_05;
  }

  &.Taken {
    background-color: @green_04;
  }

  &.Release {
    background-color: @blue_01;
  }

  &.QueuePositionInfo {
    background-color: @red3;
  }

  &.Deny {
    background-color: @gray5;
  }

  &.EndRequest {
    background-color: #e97451;
  }

  &.EndResponse {
    background-color: #a04300;
  }

  &.ReceiveMediaRequest {
    background-color: #179951;
  }

  &.ReceiveMediaResponse {
    background-color: #940c82;
  }

  &.MediaTransmissionNotifiy {
    background-color: #75943b;
  }

  &.MediaReceptionNotifiy {
    background-color: #5bafc4;
  }
}

.videoPlayerModal {
  display: flex;
  flex-direction: column;

  .ant-modal-body {
    padding: 15px 25px;
    padding-bottom: 36px;

    .filters {
      display: flex;

      &.hidden {
        visibility: hidden;
      }

      &__item {
        display: flex;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: @grey_06;
        padding: 5px 10px;
        background-color: transparent;
        border: 1px solid #cfcdcd;
        border-radius: 2px;
        user-select: none;

        &:nth-child(-n + 2) {
          background-color: #fafafa;
        }

        &:not(:last-child) {
          margin-right: 7px;
        }

        &:hover {
          cursor: pointer;
        }

        &.active {
          border: 1px solid transparent;
          color: @white;
          .marker-colors();
        }
      }
    }

    .screen {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto 35px;
      max-width: 750px;
      min-width: 700px;
      min-height: 300px;
      background: @grey_06;
      border-radius: 4px;

      svg {
        fill: @gray4;
      }

      .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
        opacity: 1;
        pointer-events: auto;
      }

      .video-js {
        background-color: @gray2;
        background-image: url(../../icons/ic_ptt_call.svg);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
      }

      video {
        border-radius: 4px;
        opacity: 0;
      }

      .video-js .vjs-progress-control:hover .vjs-progress-holder {
        font-size: 10px;
      }

      .vjs-big-play-button {
        display: none;
      }

      .vjs-custom-waiting .vjs-loading-spinner {
        display: block;
        visibility: visible;
        z-index: 666;
      }

      .video-js.vjs-custom-waiting .vjs-loading-spinner:before,
      .video-js.vjs-custom-waiting .vjs-loading-spinner:after {
        -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8)
            infinite,
          vjs-spinner-fade 1.1s linear infinite;
        animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
          vjs-spinner-fade 1.1s linear infinite;
      }

      .vjs-control-bar {
        background-color: transparent;

        .vjs-progress-control {
          position: absolute;
          top: 55px;
          width: 100%;
          right: 0;

          @media screen and (min-width: 1270px) {
            width: 1185px;
            left: -215px;
          }

          .vjs-slider {
            background-color: @white;
            border: 0.5px solid rgba(207, 205, 205, 0.5);
            border-radius: 2px;
            margin: 0;
          }

          .vjs-load-progress div {
            background-color: #f5f5f5;
          }

          .vjs-mouse-display {
            display: none;
          }
        }

        .vjs-slider-horizontal {
          height: 14px;
        }

        .vjs-play-progress {
          background-color: @grey_08;

          .vjs-time-tooltip {
            display: none;
          }

          &::before {
            content: '';
            z-index: 999;
            width: 4px;
            height: 18px;
            background-color: @gray4;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 2px;
            transform: translateX(-50%);
          }
        }
      }
    }

    .progressbar {
      display: flex;
      height: 14px;
      background: #f5f5f5;
      border: 0.5px solid rgba(207, 205, 205, 0.5);
      border-radius: 2px;

      &.hidden {
        visibility: hidden;
      }
    }

    .bottomPanel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 55px;

      .time {
        color: @grey_05;
        font-size: 12px;
        line-height: 14px;
      }

      .playControls {
        display: flex;
        justify-content: center;
        align-items: center;

        .playpausebtn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background-color: @green_04;
          color: @white;
          border-radius: 50%;
        }

        > * {
          &:not(:last-child) {
            margin-right: 10px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  /** __________MARKERS__________ */
  .vjs-marker {
    position: absolute;
    left: 0;
    top: -5px;
    bottom: 0em;
    opacity: 1;
    width: 10px;
    height: 39px;
    transition: opacity 0.2s ease;
    z-index: 100;
    background-color: @green_04;
    mask: url('../../icons/ic_time_marker.svg') no-repeat center;
    transform: translateX(-25%);

    .marker-colors();

    &:hover {
      cursor: pointer;
    }
  }

  .vjs-tip {
    visibility: hidden;
    display: block;
    opacity: 0.8;
    font-size: 10px;
    position: absolute;
    bottom: 14px;
    z-index: 99;
    margin-left: 2px;
    background-color: @green_04;

    .marker-colors();

    .vjs-tip-inner {
      border-radius: 2px 2px 2px 0px;
      padding: 5px 10px;
      color: white;
      max-width: 200px;
      text-align: center;
    }
  }

  .vjs-break-overlay {
    visibility: hidden;
    position: absolute;
    z-index: 100000;
    top: 0;

    .vjs-break-overlay-text {
      padding: 9px;
      text-align: center;
    }
  }

  .vjs-timelabel {
    position: absolute;
    left: 0;
    top: 1px;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    color: @grey_05;
  }
}
