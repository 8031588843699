@import '~antd/dist/antd.less';
@import 'theme_light.less';

body.dark,
html.dark {
  background: @white;

  .ant-menu {
    background-color: @grey_04;
  }

  .ant-layout-sider-children {
    background-color: @grey_04 !important;
    overflow-y: scroll;
  }

  .ant-menu-item-selected {
    background-color: @white !important;

    a {
      color: @grey_02 !important;
    }

    svg {
      path {
        fill: @primary-color !important;
      }
    }
  }

  .ant-menu-item {
    background-color: @grey_04;

    a {
      color: @grey_02;
    }

    svg {
      path {
        fill: @grey_03;
      }
    }
  }

  .sidebarItem {
    background-color: @grey_04 !important;

    a {
      color: @grey_02 !important;
    }
  }

  .ant-menu-submenu {
    font-weight: normal;
    background-color: @grey_04;
    color: @grey_01;
  }

  .logo {
    background-color: @grey_04;
  }

  .t-collapse-header {
    color: #2a2728;
    background-color: rgb(200, 194, 178);
  }

  .sidebarItem.ant-menu-item-selected {
    background-color: rgb(200, 194, 178) !important;
    color: @grey_01;
  }
}
