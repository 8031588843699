@import '~antd/dist/antd.less';
@import 'colors.less';
@import '404page.less';

@font-face {
  font-family: 'Roboto';
  // src: local('FiraSans'), url(/fonts/Roboto-Regular.ttf) format('truetype');
}

// @link-color: @grey_03;
// @success-color: @green_01;
// @warning-color: #faad14;
// @error-color: #e41d1a;
// @font-size-base: 14px;
// @heading-color: @grey_03;
// @text-color: rgba(0, 0, 0, 0.65);
// @text-color-secondary: rgba(0, 0, 0, 0.45);
// @disabled-color: rgba(0, 0, 0, 0.25);
// @border-radius-base: 2px;
// @border-color-base: #afcb43;

// @btn-font-weight: normal;

// @page-header-ghost-bg: #ffffff;

// @card-head-background: #ffffff;
// @card-background: #eeeeee;
// @card-actions-background: #ffffff;
// @card-border: 1px solid #f1f5d7;

// @menu-bg: @main-background;
@menu-item-active-bg: @grey_02;
// @menu-item-color: #3e3b3c;
// @menu-item-font-size: 14px;

// @label-color: #3e3b3c;
// @input-bg: #fcf9ef;
// @input-border-color: #aecb45;
// @input-number-handler-border-color: #ffffff;
// @input-color: #3e3b3c;

// @collapse-header-bg: #585858;

@modal-header-bg: @grey_04;
// @modal-content-bg: #ffffff;
// @modal-footer-bg: #ffffff;
// @modal-heading-color: #ffffff;

// @notification-bg: #ffffff;

#root {
  height: 100vh;
  max-height: 100vh;
  min-width: 900px;
}

body,
html {
  background: @grey_02;
  font-size: @font-size-base;
  color: @light-text-color;
}

::-webkit-scrollbar-thumb {
  background-color: @grey_03;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: @grey_04;
}

::-webkit-scrollbar {
  width: 6px;
}

main {
  position: relative !important;
}

.sidebar-wrapper {
  height: 100%;
}

.sidebartop {
  display: flex;
  transition: width 0.1s ease-in-out;
  justify-content: center;

  &.folded {
    .logo {
      background-position-x: left;
      width: 25px;
    }

    .menu {
      padding: 0;

      span {
        right: -35px;
      }
    }
  }

  .logo {
    background-image: url('../../icons/ic_logo.png');
    background-repeat: no-repeat;
    width: 253px;
    height: 66px;
    background-color: #363334;
    background-position-x: 75px;
    background-position-y: center;
  }

  .menu {
    text-align: end;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66px;
    padding: 0 12px;
    position: relative;
    transition: visibility 0s, opacity 0.15s linear;
    visibility: hidden;
    opacity: 0;

    &.shown {
      visibility: visible;
      opacity: 1;

      &.noOpacity {
        span {
          background-color: rgba(54, 51, 52, 1);
        }
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: #a9a9a9;
    }

    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(40%, -45%);
      padding: 5px;
      cursor: pointer;
      border-radius: 50%;
      background-color: rgba(54, 51, 52, 0.75);
    }
  }
}

.ant-card-actions {
  border-top: 0;
}

.ant-layout-header {
  padding-right: 20px;
  background-color: @page-header-ghost-bg;
  height: 66px;
  font-size: 18px;
  color: @dark-text-color;
  font-weight: bold;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .avatar {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    .avatar_icon {
      margin-right: 8px;
    }
  }

  .avatar:hover {
    color: @primary-color !important;
  }
}

.avatar_context_menu {
  background-color: @white !important;
  width: 200px;
  float: right;
  // margin-right: -20px;
  // left:calc(~'100% - 300px');
  border: solid 1px #e3e3e3;

  .context_menu_element {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      g {
        path {
          transition: fill 0.5s !important;
          fill: @grey_01 !important;
        }
      }
    }
  }

  .context_menu_element:hover {
    svg {
      g {
        path {
          transition: fill 0.5s !important;
          fill: @primary-color !important;
        }
      }
    }
  }

  ul {
    background-color: @white !important;

    li {
      background-color: @white !important;
      justify-content: center;
      align-items: center;
      color: @grey_02;
    }
  }
}

.ant-layout-footer {
  text-align: right;
  font-size: 13px;
  padding: 15px 35px;
}

.header-title {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: @dark-text-color;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.header-buttons {
  // flex-grow: 1;

  button {
    margin-left: 20px;
    // width: 88px;

    height: 32px;
    padding: 0 10px 0 0;
    font-size: 13px;
    // color: @grey_03 !important;
    transition: background-color 0.2s !important;

    svg {
      float: left;
      margin-left: 12px;
      margin-right: 8px;
      margin-top: 1px;

      path {
        fill: @grey_03;
      }
    }
  }

  button:active {
    color: @white !important;

    svg {
      path {
        fill: @white;
      }
    }
  }
}

button {
  color: @grey_03 !important;
  transition: background-color 0.2s !important;
}

button:active {
  color: @white !important;
}

.header-username {
  font-weight: normal;
  margin-left: 10px;
  font-size: 14px;
}

.ant-card-head-title {
  color: @dark-text-color;
}

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid @main-background;
}

.ant-card-actions > li > span {
  color: @dark-text-color;
}

.ant-menu-inline {
  border-right: 0;
  //margin-top: 66px;
}

.ant-page-header-back .anticon {
  color: @dark-text-color;
}

.ant-menu-item-selected {
  background-color: @grey_01 !important;

  a {
    color: @primary-color !important;
  }

  svg {
    path {
      fill: @primary-color !important;
    }
  }
}

.ant-menu {
  background-color: @sidebar-background;
  height: auto !important;
}

.ant-menu-item {
  background-color: @sidebar-background;
  width: 100% !important;
  height: 48px !important;
  padding-top: 5px !important;

  a {
    color: @grey_04;
  }
}

.ant-menu-item::after {
  left: 0 !important;
  right: 300px !important;
  height: 24px;
  margin-top: 12px;
}

.ant-menu-submenu {
  font-weight: normal;
  background-color: @grey_01;
}

.ant-menu-submenu-title {
  margin: 0;
  // color: @white !important;
}

//.ant-menu-submenu-open ~ .ant-menu-submenu {
//  position: absolute;
//  bottom: 0;
//  height: 50px;
//  width: 264px;
//}

.ant-collapse {
  border: 1px solid #ffffff;
  border-radius: 2px;
  background-color: @main-background;
  margin-top: 4px;
}

.ant-collapse {
  .ant-collapse-item {
    border-bottom: 0;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: unset;
  float: right;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0;
}

.ant-collapse-header,
.fleet-users-user {
  border-radius: 6px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.fleet-users-user {
  color: @grey_03;
  font-size: 14px;
  padding: 6px 15px;
  background-color: @grey_04;

  .ant-icon {
    float: right;
  }
}

.ant-collapse-content {
  background: @main-background;
  border: none;

  .ant-collapse-content-box {
    padding: 16px 0 16px 16px;
    border: none;
  }
}

.ant-collapse-extra {
  display: flex;
  margin-top: 3px;
  margin-left: 10px;
}

//.error {
//  .ant-collapse-header {
//    border-color: #e41d1a;
//    border-style: solid;
//    border-width: 1px;
//  }
//}

.ant-input {
  border-color: @border-color;
  border-radius: 4px;
  background-color: @white !important;
  color: @light-text-color;
}

.ant-input-group-addon {
  background-color: transparent;
  border-left: 0;
  border-collapse: collapse;
}

.ant-form-item-has-error {
  .ant-input {
    border-right: 0;
  }

  svg {
    fill: @error-color;
  }
}

.ant-form-item-has-error:not(.ant-input-disabled) {
  .ant-input:focus {
    box-shadow: 0 0 0 0;
  }
}

.array-card {
  background: @main-background;
  margin-top: 15px;
}

.array-card .ant-card-head {
  background: @main-background;
  color: @grey_04;
  border-bottom: 1px solid #ffffff;
}

.ant-card-bordered {
  border: none;
}

.ant-modal-body {
  background: @modal-content-bg;
}

.ant-input::placeholder {
  color: @input-placeholder-color;
}

.ant-notification-notice {
  background: @notification-bg;

  &.closable {
    &:hover {
      cursor: pointer;
    }
  }
}

.ant-notification-notice-message,
.ant-notification-notice-close {
  color: @notification-color;
}

.ant-notification-notice-close:hover {
  color: @notification-color;
}

.ant-card-actions > li > span a:not(.ant-btn) {
  color: @primary-color;
  letter-spacing: 0.5px;
}

.ant-modal-close-x:hover {
  color: @error-color;
}

.spiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.ant-layout-sider {
  z-index: 1001;
}

.ant-layout-sider-children {
  background-color: @sidebar-background !important;
  overflow-y: none;
}

.ant-layout {
  background-color: @main-background;
}

.ant-tabs-tab {
  text-align: center;
  width: auto;

  &:nth-last-child(2) {
    margin-right: 16px;
  }
}

.ant-tabs-card-content {
  background-color: @white;
}

.ant-tabs-nav {
  .ant-tabs-nav-operations {
    border-top: 1px solid #f0f0f0;

    button:hover {
      cursor: pointer;
    }
  }
}

.ant-tabs-nav {
  color: @light-text-color !important;
  text-shadow: none !important;
}

.ant-tabs-extra-content {
  display: none;
}

.tab-icon-item {
  fill: @grey_05;
}

#logout-btn {
  margin-top: 18px;
}

a:hover {
  color: @primary-color;
}

a {
  cursor: pointer;
}

.ant-drawer {
  top: 66px;
  bottom: 100px;

  .ant-tabs-nav {
    align-items: stretch;

    > div {
      display: flex;
    }
  }

  .ant-tabs-nav-container-scrolling {
    background-color: @white;
  }

  .ant-tabs-nav-container {
    background-color: @white;
  }

  .ant-tabs-tab {
    background-color: @white !important;
    height: 46px;
    padding: 12px 16px !important;

    div {
      display: flex;
      align-items: center;

      .tab-icon-item {
        margin-right: 4px;
      }

      .anticon {
        line-height: 20px;
      }
    }

    &-active {
      background-color: @white !important;

      .ant-tabs-tab-btn {
        color: @black !important;
        text-shadow: none;
      }

      .tab-icon-item {
        fill: @green_03 !important;
      }
    }
  }

  .ant-tabs-tab-prev {
    background-color: @white;
  }

  .ant-tabs-tab-next {
    background-color: @white;
    right: 44px;
  }

  .ant-tabs-nav-wrap {
    background-color: @white;
    width: 93%;
    height: 46px;
  }

  .ant-drawer-content {
    background-color: rgb(214, 213, 210);
    overflow: hidden;
  }

  .ant-drawer-header {
    background: red;
    height: 50px;

    border: 1px solid @border-color;
  }

  .ant-drawer-body {
    overflow: hidden;
    padding: 0;
    background-color: @white;
  }
}

.sidebarItem {
  text-transform: lowercase;
  padding-left: 0 !important;

  svg {
    path {
      fill: @grey_04;
    }
  }
}

.server-icon {
  margin-left: 36px;
  margin-right: 24px;
}

.fieldTooltip {
  display: flex;
  cursor: help;
  svg {
    color: @grey_03;
  }
}

.fieldTooltip.collapse {
  margin-left: -25px;
}

.ant-spin-spinning {
  width: 100%;
}

.ant-spin {
  background-color: @white;
  padding-top: 50px;

  &.spin_transp {
    background-color: transparent;
  }
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.ant-card {
  border-radius: 0;

  .ant-card-body {
    background-color: @white;

    form {
      margin-bottom: 30px;
    }
  }
}

.tabPanel {
  background-color: gray;

  .ant-tabs-content {
    max-height: calc(~'100vh - 170px');
    overflow-y: auto;
    border-top: 1px solid @divider-color;
  }

  .editingMark {
    position: absolute;
    right: 35px;
    color: @primary-color;
    font-size: 24px;
  }

  .userTabs {
    .ant-tabs-content {
      max-height: none;
    }

    .editMarker {
      position: absolute;
      right: 10px;
      top: 0;
      color: @primary-color;
      font-size: 24px;
    }
  }

  .ant-tabs-nav-operations {
    background-color: white;

    button {
      cursor: pointer;
    }
  }

  .ant-tabs-extra-content {
    background-color: white;

    .closeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;

      svg {
        transition: all 0.3s;

        &:hover {
          cursor: pointer;
          fill: @primary-color;
        }
      }
    }
  }
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: @grey_03 !important;
}

.ant-btn-primary:hover {
  color: @grey_03 !important;
}

.ant-btn-primary:active {
  color: white !important;
}

.ant-btn-dangerous {
  background: @error-color;
  color: white !important;
}

.ant-btn-loading.ant-btn-dangerous {
  background: @error-color;
  color: white !important;
}

.ant-btn-primary {
  color: white !important;
}

.ant-row {
  display: inline;
}

.form-element-container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  border-top: 1px solid @border-color;

  &:first-child {
    padding-top: 0px;
    border-top: none;
  }

  &.noBorder {
    border-top: none;
  }

  &.disabled-field {
    opacity: 0.5;
  }
}

.batch-wrap {
  position: relative;

  .batch-checkbox {
    position: absolute;
    margin-left: 0;
    height: 26px;
    align-items: center;
  }

  label {
    margin-left: 25px;
  }
}

.form-element-boolean {
  height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-form-item-control {
    button {
      float: right;
    }
  }
}

.custom-user-add-btn-wrap {
  display: flex;
  justify-content: space-between;
}

.custom-user-header {
  .custom-user-input {
    margin: 0;
  }
}

.form-username {
  display: flex;
  flex-direction: row;
  align-items: center;

  .form-username-input {
    flex-grow: 1;
  }

  .form-username-domain {
    margin: 4px 0 0 6px;
  }
}

.ant-tabs-ink-bar {
  visibility: visible !important;
  height: 3px !important;
  bottom: 0 !important;
}

.ant-table-cell.ant-table-cell-scrollbar {
  display: none;
}

.ant-table-cell {
  .anticon.anticon-search {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    a {
      color: @primary-color;
    }
  }
}

.mainLayout {
  // height: -webkit-fill-available;
  // max-height: calc(~'100vh - 170px');
  height: 100vh;
  max-height: 100vh;

  .content {
    overflow: hidden;
  }
}

.compressed {
  max-width: calc(~'100% - 499px');
  min-width: 630px;
}

.ant-menu-sub.ant-menu-inline {
  background-color: @sidebar-background;
}

.commandPanel {
  min-height: 48px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -1px;
  border-bottom: 1px solid @divider-color;
  border-top: 1px solid @divider-color;
}

.control-btns {
  flex-grow: 1;

  .control-btn {
    padding: 0;
    border-color: white !important;
    margin-right: 24px;
    transition: background-color 0.2s !important;
    border-radius: 5px;

    svg {
      width: 28px;
      height: 28px;
      margin: 0;
    }
  }

  .control-btn:hover {
    border-color: @green_02;
    background-color: @green_02;
  }

  .control-btn:disabled {
    background-color: transparent;

    svg {
      path {
        fill: @grey_04 !important;
      }
    }
  }

  .control-btn:enabled:active {
    background-color: @primary-color;

    svg {
      path {
        fill: @white !important;
      }
    }
  }
}

.search-panel {
  ::placeholder {
    color: #959393;
  }

  .ant-input-search.ant-input-affix-wrapper {
    width: 150px;
  }
}

.main-tabs-panel {
  .ant-tabs-nav {
    padding-left: 20px;
  }
}

.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
}

.site-layout-background {
  padding-top: 0 !important;
}

#tab-1 {
  width: 100px;
}

#tab-2 {
  width: 60px;
}

#tab-3 {
  width: 65px;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
  // padding: 1px;
  background-color: @grey_04;
  height: calc(~'100vh - 116px') !important;
  border: 1px solid @border-color;
}

.panelWrapper {
  .ant-tabs-nav-wrap {
    border-top: 1px solid @border-color;

    &:after {
      opacity: 1 !important;
    }
  }
}

.contentTable {
  position: relative;
  border-radius: 4px;
  height: 100%;
}

.contentTableUnSelectable {
  &:extend(.contentTable);

  tr > th:first-child {
    padding-left: 20px !important;
  }

  tr > td:first-child {
    padding-left: 20px !important;
  }
}

.dashboard {
  overflow-y: auto;
  height: 100%;
}

.ant-table-thead > tr > th {
  height: 48px;
  padding: 4px;
  background: @white;
  color: @light-text-color;
}

.ant-table-thead > tr > th:first-child {
  height: 48px;
  padding: 4px 4px 4px 24px;
  background: @white;
}

.ant-table-body {
  height: calc(100vh - 324px);
  overflow-y: auto !important;

  td {
    height: 48px;
    padding: 4px;
    cursor: pointer;
    color: @light-text-color;
  }
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  color: @green_01;
  height: 0px;
  align-items: center;
}

.ant-table .ant-table-selection-col {
  width: 60px !important;
}

.ant-radio-wrapper {
  display: none;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: @green_02;
  color: @light-text-color;
}

.ant-table-filter-column {
  .ant-table-column-title {
    width: 100%;
    white-space: nowrap;

    > div {
      display: flex;
    }
  }
}

.ant-table-filter-trigger-container {
  transition: none;

  :hover {
    background-color: @white;
  }

  .ant-table-filter-trigger {
    font-size: 16px;
    color: @grey_03;

    :hover {
      background-color: @white;
      color: @primary-color;
    }
  }
}

.ant-switch {
  min-width: 42px !important;
  height: 20px !important;
}

.ant-switch-handle::before {
  width: 16px;
  height: 16px;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px);
}

.ant-btn {
  border: 1px solid rgb(218, 215, 218) !important;
  color: gray;
}

.ant-btn:hover {
  background: @green_02;
  color: @grey_03;
}

.ant-btn:active {
  background: @primary-color;
  color: @grey_04;
}

.ant-table-pagination.ant-pagination {
  width: 100%;
  // background-color: @grey_04;
  height: 48px;
  padding: 10px 0 0 20px;
  border-top: 1px solid @divider-color;
  margin-bottom: 0;

  input {
    color: @grey_03;
    border: none;
  }

  .ant-select-selector {
    color: @grey_03;
    border: none;
  }

  .ant-pagination-item-active {
    border: none;

    a {
      color: @grey_03;
    }
  }
}

.customPaginationWrapper {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .drawerShowed {
    right: 630px;
  }
}

.refreshBtn {
  position: absolute;
  border: none !important;
  bottom: 0;
  right: 40px;
}

label {
  color: @grey_02;
}

.form-search-input {
  background-color: @white;
  width: 100%;

  ::placeholder {
    color: @grey_04;
  }
}

@keyframes blinkingText {
  0% {
    background-color: @green_02;
  }
  49% {
    background-color: @green_02;
  }
  60% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: @green_02;
  }
}

.blinked {
  // background-color: @green_02;
  animation: blinkingText 0.25s linear infinite;
}

.divider {
  margin: 10px;
}

.picker-disabled {
  pointer-events: none;
  opacity: 0.4;
}

//============TCOLLAPSE==========
.t-collapse-header {
  color: @white;
  background-color: @grey_01;
  height: 46px;
}

.t-collapse-header-postfix {
  cursor: pointer;
  color: @grey_03;

  :hover {
    color: @primary-color;
  }
}

.t-collapse-header-prefix {
  cursor: pointer;
  color: @grey_03;

  :hover {
    color: @primary-color;
  }
}

.t-collapse-header-search {
  input {
    background-color: @grey_02;
  }

  :focus {
    border: none;
  }

  .t-collapse-header-search-close {
    cursor: pointer;
    color: @grey_03;

    :hover {
      color: @primary-color;
    }
  }
}

.filterFilled {
  color: @primary-color;
}

.filterBox {
  display: grid;
  float: right;
  line-height: 1.3;
  margin-right: 2px;
  position: relative;
  padding-right: 20px;
  width: 100%;
  text-overflow: ellipsis;
  justify-content: flex-end;
  padding-left: 5px;
  align-items: center;

  .filterBox__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .closeFilter {
    position: absolute;
    right: 0;
    top: 3px;

    :hover {
      color: @primary-color;
    }
  }
}

.search_btn_icon {
  fill: white;
  float: left;

  :active {
    transition: fill 0.5s !important;
    fill: @grey_01 !important;
  }
}

///////Drawer-search///////
.drawer-search-wrapper {
  top: 0;
  position: sticky;
  width: 100%;
  height: 60px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: @white;

  .ant-input-search {
    border-radius: 4px;
  }
}

//TRANSFER
.ant-transfer-operation {
  button:disabled {
    svg {
      fill: @grey_04;
    }
  }
}

input::placeholder {
  color: @grey_04 !important;
}

.ant-transfer-list-content {
  margin-top: 10px;
}

.ant-transfer-list-content {
  height: 190px;
  width: 250px;
}

.ant-empty-image {
  display: none;
}

.users-transfer-wrap {
  display: flex;
  flex-direction: column;

  .ant-transfer {
    display: flex;
    width: 100%;

    .ant-transfer-list {
      width: 45%;
      height: 300px;
    }
  }
}

.group-select,
.users-select {
  width: 100%;
}

.fleet-users-select-wrap,
.group-users-select-wrap,
.group-status-select-wrap {
  display: flex;

  .group-users-add-btn,
  .group-status-add-btn {
    padding: 0;
    margin: 0 0 0 6px;
  }
}

.fleet-users-select,
.group-users-select,
.group-status-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .ant-select-selector {
    width: 100%;
  }
}

.custom-user-item,
.fleet-users-item,
.group-users-item,
.group-status-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid @divider-color;

  .custom-user-collapse,
  .fleet-users-user,
  .group-users-collapse,
  .group-status-collapse {
    width: 100%;
    margin: 0;

    .group-status-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group-status-color {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }
  }

  .custom-user-delete-btn,
  .fleet-users-delete-btn,
  .group-users-delete-btn,
  .group-status-delete-btn {
    margin: 2px 0 0 6px;
  }
}

.group-users-add-modal {
  .ant-modal-body {
    padding: 0;

    .group-users-add-modal-body {
      display: flex;

      .group-users-add-modal-transfer {
        flex-grow: 6;
      }

      .group-users-add-modal-card {
        flex-grow: 1;
        .ant-card-body {
          padding-left: 0;
        }
      }
    }
  }
}

.experimental {
  color: @error-color;
}

.license-info-wrap,
.restriction-wrap {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;

  .ant-card-head-wrapper {
    height: 60px;
  }
  .license-card,
  .info-card,
  .restriction-card {
    border: 1px solid @border-color;
    border-radius: 4px;
    margin: 12px;
    min-width: 290px;

    .ant-spin {
      padding-top: 0;
    }

    .license-info-item,
    .restriction-item {
      display: flex;
      line-height: 32px;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.badge {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.badge-success {
  background-color: @green_01;
}

.badge-error {
  background-color: @error-color;
}

.badge-default {
  background-color: @grey_03;
}

.license_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;

  svg {
    width: 15px;
    height: 15px;
    fill: @grey_03;
  }

  &.confirm {
    svg {
      width: 25px;
      height: 25px;
      fill: @green_01;
    }
  }
}

.sidebar-collapse-wrapper {
  .ant-collapse {
    background-color: transparent;
    border: none;

    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        background-color: @grey_01;
        border-radius: 0 !important;
        height: 48px;

        .ant-collapse-arrow {
          position: unset;
          float: left;
          margin-top: 0;
          margin-left: 20px;
          padding: 0;

          svg {
            height: 14px;
            width: 14px;
            &:hover {
              fill: @green_01;
            }
          }
        }
      }
    }

    .ant-collapse-content {
      background-color: @sidebar-background;
      overflow: auto;
    }

    .ant-collapse-content-box {
      background-color: @sidebar-background;
      padding: 0;
    }
  }

  .domain-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    svg {
      &:hover {
        fill: @green_01;
      }
    }

    input {
      background-color: @grey_02;
      color: @white;
      width: 210px;
      border-radius: 5px;
      border: none;
      padding: 3px 15px;
      outline: none;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .panel-title {
    color: @white;
    padding-left: 15px;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: color 0.3s;

    &-active {
      color: @green_01;
    }
  }
  .panel-buttons {
    .anticon {
      margin-left: 11px;
    }
  }
  .domain-mco-name {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: @gray5;
    position: absolute;
    left: 77px;
    top: 34px;
  }
  .panel-popup {
    position: absolute;
    top: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 16px;
    gap: 10px;
    width: auto;
    background: @gray2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-weight: 400;

    &-title {
      color: @white;
      font-size: 16px;
      line-height: 19px;
    }
    &-close {
      position: fixed;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 99;
    }
    .ant-radio + * {
      font-size: 12px;
      line-height: 14px;
      color: @grey_08;
    }
    .ant-radio-wrapper {
      display: inline-flex;
    }
  }
  .menu-item {
    padding-left: 40px;
  }
}

.tooltip-with-helper {
  a {
    padding-left: 5px;
  }
}

.modal-wrapper {
  .ant-form-item-control {
    padding-bottom: 10px;
  }
  .ant-radio-wrapper {
    display: inline-flex;
  }
}

.apiDocLinks {
  color: @grey_01;
  cursor: pointer !important;
  display: block;
  width: 100%;
}

.formControlButtonsWrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 120px;
  width: 451px;
  height: 50px;
  padding-top: 10px;
  background-color: @white;
  z-index: 1;
  button + button {
    margin-left: 10px;
  }
}

.collapseLocationTriggerWrapper {
  .ant-input-number {
    width: 100%;
  }

  .triggerPanel {
    margin-bottom: 5px;
  }

  .addNewTrigger {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .criteriaParameters {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .collapseWrapper {
      width: 100%;

      .criteriaPanel {
        width: 100%;
      }
    }

    button {
      margin-top: 5px;
      margin-left: 5px;
    }
  }
}

.addNewTriggerBtn {
  display: flex;
  align-items: center;
}

.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.timeRangeBtn {
  display: flex;
  padding: 0 10px;
  align-items: center;

  span {
    padding-left: 5px;
  }

  svg {
    width: 18px !important;
    margin-right: 5px;
  }
}

.timeRangePickerWrapper {
  width: fit-content;

  .ant-btn {
    display: flex;
  }

  .timeRangeWrapper {
    position: fixed;
    z-index: 999;
    margin-top: 5px;
    background-color: @white;
    border: 1px solid @grey_04;
    height: 260px;
    width: 450px;
    box-shadow: 5px 5px 10px -14px @black;

    .wrapper {
      display: flex;

      .flexWrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;

        span {
          cursor: pointer;
          color: @green_01;

          &:hover {
            color: @green_03;
          }
        }
      }
    }

    .ant-tabs-nav-wrap {
      &:after {
        box-shadow: none;
      }
    }
  }
}

.absoluteTabWrapper {
  padding: 20px;

  .headerWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .itemWrapper {
      display: flex;
      justify-content: space-between;
      width: 50%;

      .actionSet {
        cursor: pointer;
        color: @green_01;

        &:hover {
          color: @green_03;
        }
      }
    }

    .itemWrapper + .itemWrapper {
      padding-left: 10px;
    }
  }

  .rangePicker {
    // position: static;
    width: 100%;
  }
}
.backup-info-item {
  display: flex;
  line-height: 32px;
  justify-content: space-between;
  align-items: center;
}
.backup-subscription-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  .item-title {
    width: 100%;
    margin-bottom: 8px;
  }
}

.draggable-item {
  z-index: 99999;
  cursor: pointer;
}
.sortable-item {
  cursor: pointer;
  user-select: none;
}

.call-icon {
  height: 20px;
  width: 100%;
  color: @grey_03;
  padding-left: 10px;

  svg {
    height: 100%;
    width: auto;
  }
}

.minimizedUpdateVersionModal {
  display: flex;
  padding: 0;
  width: 443px;

  .ant-notification-notice-content {
    width: 100%;
  }

  .ant-notification-notice-close,
  .ant-notification-notice-message {
    display: none;
  }
}

/** for filterDropdown to be properly located */
.ant-dropdown-placement-bottomRight:has(.ant-table-filter-dropdown) {
  left: unset !important;
  right: 10px;
}
.ant-form-item-explain:has(div:empty) {
  display: none
}
@import 'map_tools.less';
@import 'call_history.less';
@import 'framework.less';
